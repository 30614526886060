import React from 'react'
import ProductDetail from '../component/ProductDetail/ProductDetail'
const Product = () => {
  return (
    <>
      <ProductDetail/>
    </>
  )
}

export default Product
