import CartC from "../component/Cart/Cart";


import React from 'react'

const Cart = () => {
  return (
    <CartC/>
  )
}

export default Cart
