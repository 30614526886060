import React from 'react'

const Notfound = () => {
  return (
    <section> 
        <div className='max-padd-container py-8 bg-primary'>
            <h2 className='h3 '>404 PAGE NOT FOUND</h2>
        </div>
    </section>
  )
}

export default Notfound
